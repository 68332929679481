<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol style="font-size: 25px" col="6"> الإعلانات</CCol>
            <CCol col="6" style="text-align: left">
              <CButton to="/ads/Create" square color="primary">
                <CIcon class="c-icon" name="cil-chevron-top" /> &emsp; إضافة إعلان
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <!-- <CCardHeader>
          الإعلانات
        </CCardHeader>
        <CButton block color="primary" to="/ads/Create">إنشاء إعلان فرعية جديدة</CButton>
        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0 text-center">
            <br>
            <CButton  color="primary" class="btn-primaryadd btn-block" square to="/ads/Create">إضافة إعلان فرعية</CButton>
        </CCol> -->
        <CCardBody>
          <!-- Using the VdtnetTable component -->
          <vdtnet-table
            ref="table"
            :fields="fields"
            :opts="options"
            @edit="doAlertEdit"
            @show="doAlertShow"
            @delete="doAlertDelete"
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import $ from "jquery";
import swal from "sweetalert";
import VdtnetTable from "../VdtnetTable.vue";
export default {
  name: "SubCategories",
  components: { VdtnetTable },
  data() {
    const vm = this;
    return {
      options: {
        ajax: {
          beforeSend: function(xhr) {
            $('.loading').show()
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.token);
          },
          url: `${process.env.VUE_APP_URL}ads`,
          dataSrc: json => {
            $('.loading').hide()
            return json.data;
          }
        },
        
        buttons: [
          {
            extend: "print",
            text: "",
            title: "الطلبات",
            titleAttr: "طباعة",
            className: 'fa fa-print btn-primary',
          },
          {
            extend: "copy",
            text: "",
            title: "الطلبات",
            className: 'fa fa-copy btn-warning',
            titleAttr: "نسخ"
          },
          {
            extend: "excel",
            text: "",
            title: "الطلبات",
            titleAttr: "إكسل",
            className: 'fa fa-table btn-success',
            exportOptions: {
              columns: "th:not(:last-child)"
            }
          }
        ],
        /*eslint-disable */
        // dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'>>",
        /*eslint-enable */
        dom:
          "<'row'><'col-sm-6 col-md-6'f><'col-sm-6 col-md-6 added'l>" +
          "<'row'><'col-sm-12'Btr>" +
          "<'row'><'col-sm-12 col-md-5'p><'col-sm-12 col-md-7'i>",
        paging: true,
        language: {
          url: "https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json"
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: {
          style: "multi"
        }
      },
      fields: {
        // id: { label: '#', sortable: true},
        image: {
          label: "الصورة",
          data: "image"
        },
        category: {
          label: "الفئة",
          sortable: true,
          searchable: true
        },
        expire_date: {
          label: "تاريخ الإنتهاء",
          sortable: true,
          searchable: true
        },

        created_at: {
          label: "تاريخ الإنشاء",
          sortable: true,
          searchable: true
        },
        actions: {
          isLocal: true,
          label: "العمليات",
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit" class="btn btn-dark btn-sm"><i class="mdi mdi-square-edit-outline"></i> تعديل</a>' +
            '&ensp;<span data-action="delete" class="btn btn-danger btn-sm"><i class="mdi mdi-delete"></i> حذف</span>'
        }
      },
      quickSearch: "",
      details: {}
    };
  },
  methods: {
    doAlertShow(data) {
      this.$router.push({
        path: `/ads/${data.id}/show`,
        params: { id: data.id }
      });
    },
    doLoadTable(cb) {
      this.axios.getJSON("http://localhost:8000/api/ads", function(data) {
        cb(data.data);
      });
    },
    doAlertEdit(data) {
      this.$router.push({
        path: `/ads/${data.id}/edit`,
        params: { id: data.id }
      });
    },
    doAlertDelete(data, row, tr, target) {
      console.log(`deleting item ID: ${data.id}`);

      swal({
        title: "هل انت متأكد ؟",
        text: "بمجرد الحذف ، لن تتمكن من استرداد هذا العنصر !",
        icon: "warning",
        buttons: ["لا", "نعم"],
        dangerMode: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      }).then(willDelete => {
        if (willDelete) {
          this.$http
            .delete(`${process.env.VUE_APP_URL}ads/${data.id}`, {
              _method: "delete"
            })
            .then(response => {
              // let i = this.data.map(item => item.id).indexOf(id); // find index of your object
              // this.data.splice(i, 1);
              swal({ title: response.data.message, buttons: "تم" });
            });
          tr.remove();
          const table = this.$refs.table;
          setTimeout(() => {
            // simulate extra long running ajax
            table.reload();
          }, 1500);
        } else {
          swal({ title: " تم الغاء الحذف !", buttons: "تم" });
        }
      });
    },
    doAfterReload(data, table) {
      console.log("data reloaded");
    },
    doCreating(comp, el) {
      console.log("creating");
    },
    doCreated(comp) {
      console.log("created");
    },
    doSearch() {
      this.$refs.table.search(this.quickSearch);
    },
    doExport(type) {
      const parms = this.$refs.table.getServerParams();
      parms.export = type;
      window.alert("GET /api/v1/export?" + jQuery.param(parms));
    },
    formatCode(zipcode) {
      return zipcode.split("-")[0];
    }
  }
};
</script>

<style scoped></style>
